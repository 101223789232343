import React, { useState, useEffect, useRef, forwardRef } from 'react'
import Select from './select'
const option0 = {value: '', label: '\u00A0'}
export default forwardRef((props: any, ref) => {
  const { defaultValue, label, min = 1900, max = new Date().getFullYear(), isDisabled=false, onChange, required =false} = props
  const [ options, setOptions ] = useState<any>([])
  const __ref = useRef({})
  const yearRef: any = ref || __ref
 
  useEffect(() => { 
    const _options = []
    // _options.push({value: '', label: '\u00A0'})
    for( let i = max; i > min; i-- ){
      _options.push({value: i, label: `${i}`})
    }
    setOptions(_options)
   }, [max, min])

  const handleChange = (option: any) => onChange(option ? option.value : option0.value)
  const getDefault = () => options.filter((e: any) => e.value === defaultValue)[0]
  
  return (
    <div className='year'>
      <Select ref={yearRef} label={label} blockMsg={`invalid ${label}`} selected={getDefault()} onChange={handleChange} placeholder='Year' isDisabled={isDisabled}
      options={required ? options : [option0, ...options]}/>
    </div>
  )
})


