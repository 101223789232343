import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { Modal, Span, Button, Scrollbar } from 'components/common'
import { newId } from 'utils'
import './style.scss'

class StepType{
  label!: string;
  header!: string;
  description!: any;
  img!: any;
}
class propsType{
  className!: string;
  steps!: [StepType];
  onHide: any;
}
export default forwardRef((props: propsType, ref: any) => {
  const { steps, className, onHide=()=>{} } = props
  const [ step, setStep ] = useState(0)
  const modalRef: any = useRef({})

  useImperativeHandle(ref, () => ({
    show(){
      setStep(0)
      modalRef.current.show()
    }
  }))
  
  const onBack = () => {
    setStep(step - 1);
    resetFocus()   
  }

  const onNext = () => {
    setStep(step + 1);
    resetFocus()    
  }

  const resetFocus = () => setTimeout(() => {
    const refocusEle: any = document.querySelector('wizard-modal .wizard-container')
    refocusEle?.focus();
  }, 500)

  const wizardStep = steps[step]
  const maxStep = steps.length - 1
  
  const onClose = () => modalRef.current.hide()

  const aodaProps = {describedBy: newId(), labelledBy: newId()}
  return (<Modal ref={modalRef} onHide={onHide} {...aodaProps} className={`${className} wizard-modal step${step}`}>
    <div className='wizard-modal-container'><div className='wizard'><Scrollbar>
      {step === 0 ? <span/> :
      <Span className='step-indicator pre' onClick={onBack}>
        <div className='step-indicator-icon'>
          <img alt='' aria-hidden='true' src='./img/icon-arrow.svg'/>
        </div>
        <span tabIndex={1} className='step-indicator-label'>Back</span>
      </Span>
      }
      <div tabIndex={0} className='wizard-container'>
        <div style={{margin: '0 65px'}}>
          <div className='simple-row wizard-step'>
            <img aria-hidden='true' alt='FORTE' style={{width: '30px'}} src='./img/forte-Small-20px.png'/>
            <span className='sr-only'>List Collaboration, step</span>{wizardStep.label}
          </div>
          <div role='heading' id={aodaProps.labelledBy} className='simple-row strong bigger'>{wizardStep.header}</div>
          <div id={aodaProps.describedBy} className='simple-row rating-section'>
            {wizardStep.description}
            {wizardStep.img && <div className='simple-row'><img alt='FORTE' {...wizardStep.img}/></div>}
          </div>
        </div>

        {step >= maxStep ? <span/> :
          <Span className='step-indicator next' onClick={onNext}>
            <div className='step-indicator-icon right'>
              <img alt='' aria-hidden='true' src='./img/icon-arrow.svg'/>
            </div>
            <span className='step-indicator-label'>Next</span>
          </Span>
        }

        <div className='simple-row wizard-footer'>
          {step === maxStep ? 
          <Button className='tms-button primary' onClick={onClose}>Close</Button>
          :
          <Button className='tms-button primary' onClick={onClose}>Skip</Button>
          }
        </div>
      </div>
    </Scrollbar></div></div>
  </Modal>)
})