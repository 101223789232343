import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle} from 'react'
import { Key, newId } from 'utils'
import ResizeDetector from '../resize-detector'
import Scrollbar from '../scroll-bar'

import './dropdown.scss'

export default forwardRef((props: any, ref) =>  {
  const { 
    className, style, menuClassName, menuStyle, id, menuRole="button", /*required=false, */focusable=false, labelledBy, menuLabelledBy, menuDescribedBy, menuLabel='dropdown',
    display, children, scrollable=false, pullRight = false, pullUp = false, autoHide = false, fixed=false, nextFocus,
    menuHeader,
    onDropMenu=()=>{},
    onKeyDown=()=>{},
    onBlur=()=>{},
    onClick=()=>{},
    focusOnFirst=()=>{},
    clear=()=>{}
  }=props
  
  const [toggleOn, setToggleOn] = useState(false)
  const [menuHeight, setMenuHeight] = useState(0)
  const [activeElement, setActiveElement] = useState<any>({})
  const [menuId, setMenuId] = useState('')
  const wrapperRef: any = useRef({})
  const menuRef: any = useRef({})

  useEffect(()=> {
    setMenuId(!!id? id: newId())
  }, [id])

  const handleClickOutside = (event: any) => blurDropMenu(event.target)

  const handleKeypress = (event: any) => {
    if(event.keyCode === Key.ESCAPE){
      onHideDropMenu()
    }else if(event.keyCode === Key.TAB){
      setTimeout(()=>blurDropMenu(document.activeElement), 100)
    }
  }

  const blurDropMenu = (target: any) => {
    if (!wrapperRef || !wrapperRef.current || menuRef.current.contains(target)) return
    onHideDropMenu(false)
  }

  const api = () => ({
    showDropMenu: () => onShowDropMenu(),
    hideDropMenu: onHideDropMenu,
    contains: (e: any) => {return (wrapperRef.current.contains(e))},
    isToggleOn: () => toggleOn,
    focusOnFirst: () => focusOnFirst(menuRef),
    clear: () => clear(menuRef)
  })
  useImperativeHandle(ref, api)
  
  const onShowDropMenu = () =>{
    setActiveElement(document.activeElement)
    setToggleOn(true)
    onDropMenu(true)
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keyup', handleKeypress)

    if(fixed){
      const fixedParent = wrapperRef.current.closest('.e-modal-dialog')
      const parentPosition = fixedParent?.getBoundingClientRect() || {top: 0, bottom: 0, left: 0, right: 0}
      const dropRect = wrapperRef.current.getBoundingClientRect()
      menuRef.current.style.top = (dropRect.bottom - parentPosition.top) + 'px'
      menuRef.current.style.width = dropRect.width + 'px'
      
      setTimeout(() => {
        const menuRect = menuRef.current.getBoundingClientRect()
        if((parentPosition.top + dropRect.top + menuRect.height) > window.innerHeight){
          menuRef.current.style.height = (window.innerHeight - parentPosition.top - dropRect.top - 10) + 'px'
        }
      }, 1000);
      
    }
  }

  const onHideDropMenu = (restoreFocus = true, moveNext?:boolean, event?: any) =>{
    setToggleOn(false)
    onDropMenu(false, event)
    if (restoreFocus) {
      setTimeout(() => {
        if (moveNext && nextFocus) {
          let next = document.querySelector(nextFocus) 
          next && next.focus()
        } else {
          activeElement.focus && activeElement.focus()
        }
      })
    } 
    document.removeEventListener('mousedown', handleClickOutside)
    document.removeEventListener('keyup', handleKeypress)
  }

  const onMouseLeave = (event: any) => {
    if (autoHide) {
      onHideDropMenu(false)
      event.preventDefault()
    }
  }

  const onResize = (w: number, h: number) => setMenuHeight(h + 27)

  return (
    <div className={`custom-dropdown ${className} ${pullRight && 'pull-down-right'} ${fixed && 'fixed'}`} 
    ref={wrapperRef} style={{...style}} onClick={onClick} onBlur={onBlur} onKeyDown={onKeyDown} onMouseLeave={onMouseLeave}>
      <span className={`dropdown ${scrollable ? 'scrollable' : ''}`} >
        {!focusable ? 
          <span id={`${menuId}_btn`} className='dropdown-display' tabIndex={0} aria-description='Activate dropdown menu and use tab key to navigate'
            role='button' aria-haspopup={menuRole} aria-controls={menuId} aria-expanded={toggleOn} {...(menuDescribedBy ? {'aria-describedby':menuDescribedBy}:'')}
            {...(labelledBy ? {'aria-label': labelledBy} : {'aria-labelledby': `${!!menuLabelledBy ? menuLabelledBy + ' ': ''}${menuId}_btn`})}>
            { display }
          </span>
          :  display
        }
        <div ref={menuRef} id={menuId} /*role={menuRole}*/ {...(menuLabelledBy ? {'aria-labelledby': menuLabelledBy}:{'aria-label':menuLabel})}
          className={`dropdown-menu ${menuClassName} ${toggleOn && 'show'}  ${pullUp && 'pull-up'}`} 
          style={scrollable ? {...menuStyle, height: `${menuHeight}px`}: menuStyle}>
          
          { !scrollable ? children :
            <Scrollbar><div style={{display: 'flex', flexDirection: 'column'}}>
              { menuHeader ? <div className='simple-row'>{menuHeader}</div> : <></> }
              { children }
              <ResizeDetector onResize={onResize}/>
              </div>
            </Scrollbar>
          }
        </div>
      </span>
    </div>
  )
})