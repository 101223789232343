import {
  CRITERIA_AUTO_COMP_REFRESH,
  CRITERIA_AUTO_COMP_CLEAR,
  CRITERIA_SEARCH_ACTIVE_APPLY_REFRESH
} from 'services/action-types'
import { dispatchSimple, dispatchFetchAbort } from 'store'

export const clear_auto_complete = () => dispatchSimple({type: CRITERIA_AUTO_COMP_CLEAR})
export const refresh_auto_complete = (value: any) => dispatchSimple({type: CRITERIA_AUTO_COMP_REFRESH, value})
export const search_auto_complete = (value: any, callback: any=()=>{}) => dispatchFetchAbort({
  spinner: false,
  path: getUrl(value),
  method: 'POST',
  body: {
    ...value, 
    type: {
      ...value.type, 
      customDesc: '',
      children: '',
      warning: ''
    }, 
    mentorScope: value.type.mentorScope || value.mentorScope
  },
  success: (data: any)=>{
    refresh_auto_complete(data)
    callback(data)
  }
})
const getUrl = (value: any) => {
  const type = value.type.type
  if(type === 'MT_SEARCH_AUTOCOMP'){
    return 'api/mentor/profile/search/autocomp'
  }else if(type === 'RS_SEARCH_AUTOCOMP'){
    return 'api/tms/rslt/autocomp'
  }else{
    return ''
  }
}
export const refresh_active_apply = (value: any) => dispatchSimple({type: CRITERIA_SEARCH_ACTIVE_APPLY_REFRESH, value})
