import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import './list.scss'
import ListItem from './list-item'
import Paginate2 from '../paginate2'
import Scrollbar from '../scroll-bar'
import { jumpFocus, jumpFocus2 } from 'utils/nav-util'

const List2 = forwardRef((props, ref) => {
  const { rows, numOfperPage, numOfTotal, onPageChange, onSelectRow, style, children, nextFocus, escFocus } = props

  const [ listCatch, setListCatch ] = useState([])
  const [ listSnap, setListSnap ] = useState([])
  const [ pageNo, setPageNo ] = useState(0)
  const [ pageCount, setPageCount ] = useState(0)
  const [ pageMore, setPageMore ] = useState(false)
  const [ activeRow, setActiveRow ] = useState(-1)
  const listRef = useRef({})

  // useEffect(() => {
  //   setListCatch([])
  //   setListSnap([])
  //   setPageNo(0)
  //   setPageCount(0)
  //   setPageMore(false)
  // }, [reset])

  useEffect(() => {
    if(rows.length === 0){
      setPageMore(false)
    }else{
      const _pageNo = pageNo + 1
      setListCatch([...listCatch, ...rows])
      setListSnap([...rows]) 
      setPageCount(pageCount + 1)
      setPageNo(pageNo + 1)
      setPageMore(numOfTotal > numOfperPage * _pageNo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  const api = () => ({
    clear: ()=> {
      setListCatch([])
      setListSnap([])
      setPageNo(0)
      setPageCount(0)
      setPageMore(false)
    }
  })
  useImperativeHandle(ref, api)

  const onPage = pNo => {
    const start = numOfperPage * (pNo - 1), end = numOfperPage * pNo
    const _listSnap = []
    listCatch.slice(start, end).map(e=>_listSnap.push({...e, selected: false}))
    setListSnap(_listSnap)
    setPageNo(pNo)
  }

  const onPrevPage = () => {
    if(pageNo > 1){
      callPageChangeCallback(false)
      onPage(pageNo - 1)
    }
  }
  
  const onNextPage = () => {
    if(numOfperPage > listSnap.length){
      return
    }
    
    if(pageNo === listCatch.length/numOfperPage){
      callPageChangeCallback(true)
    }else{
      callPageChangeCallback(false)
      onPage(pageNo + 1)
    }
  }

  const callPageChangeCallback = requestNewpage => {
    onPageChange(requestNewpage)
    listRef.current.parentNode.scrollTop = listRef.current.offsetTop
  }

  const selectRow = (index, item, event) =>{
    onSelectRow(item, index)
    const _listSnap = [...listSnap]
    _listSnap.map((e, i)=>{
      e.selected = false
      return e
    })
    _listSnap[index] && (_listSnap[index].selected = true)

    setListSnap(_listSnap)
  }
  
  const onNavigate =(row, event) => {
    // selectRow(nextRow, listSnap[nextRow])
    if (event.keyCode === 40) {       // Down
      row < listSnap.length -1 && setActiveRow(row + 1 )
    } else if (event.keyCode === 38 ) { // Up
      row > 0 && setActiveRow(row - 1 )
    } else if (event.keyCode === 39 ) { // right
      listSnap[row].selected && jumpFocus(nextFocus)
    } else if (event.keyCode === 27 ) { // Esc
      jumpFocus2(escFocus)
    }
  }
  const styles = {background: '#fff', width: '100%', ...style}
  
  return (
    <div style={styles}>
      <div className='list list2'>
      <Scrollbar>
        <ul ref={listRef} >
        {
          listSnap.map((item, i) => 
            <li key={i} className={listSnap[i] && listSnap[i].selected ? 'itemSelected' : 'item'}>
              <ListItem
                onSelect={ ()=> selectRow(i, item)} 
                // selected={ listSnap[i].selected }
                actived={ activeRow === i }
                onNavigate={ event => onNavigate(i, event)}>
                {()=>children(item, i)}
              </ListItem>
            </li>)
        }
        </ul>
        </Scrollbar>
      </div>
      <Paginate2 pageCount={pageCount} pageNumber={pageNo} numOfperPage={numOfperPage} numOfTotal={numOfTotal} pageMore={pageMore} onPrevPage={onPrevPage} onNextPage={onNextPage}/>
    </div>
  )
})

List2.defaultProps = {
  numOfperPage: 10,
  onPageChange: ()=>{}
}

export default List2;