import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import Draggable from 'react-draggable'
import { loggerInfo, Key, newId, focusOnSelectors2, findParent} from 'utils'
import { Button } from './elements'
import './modal.scss'

const focusElements = [
  "input:not([type='checkbox']), label[tabindex='0'], div[tabindex='0']:not(.last-focus), span[tabindex='0'], .fa[tabindex='0'], textarea, a:not(.disabled)",
  "button.tms-button:not([disabled]), button.flat-button",
  "button.e-modal-close"
]
class propsType{
  header?: any;
  footer?: any;
  children?: any;
  role?: string;
  labelledBy?: string;
  describedBy?: string;
  skipFocus?=false
  restoreFocus? = true
  defaultFocus?: string
  modalName?: string;
  style?: any;
  className?: string;
  showClose?=true
  styleHeaderUnderline?: boolean=true
  headerWrapped?=true
  refocus?: string;
  onHide?:any =()=>{};
  breakHide?:any =()=>{};
}
export default forwardRef((props: propsType, ref) => {
  const { header, role, labelledBy, describedBy, skipFocus=false, restoreFocus = true, defaultFocus, modalName,
    footer, style, className, onHide=()=>{}, children, showClose=true, styleHeaderUnderline=true, headerWrapped=true, refocus, breakHide=()=>{}, ...rest } = props
  const [ showing, setShowing ] = useState(false)
  const [ headerLabelId, setHeaderLabelId ] = useState<string>('-1')
  
  const firstFocusRef: any = useRef({}), modalRef: any = useRef({})
  const lastFocusRef: any = useRef({})

  const [activeElement, setActiveElement] = useState<any>({})
  const [dndStyle, setDndStyle] = useState({})

  const handleKeypress = (event: any) => {
    if (event.keyCode === Key.ESCAPE) {
      let ddl = document.querySelectorAll(".dropdown-menu.show")   // any dropdown opened
      if (ddl.length > 0) return
      let childModals = (modalRef.current && modalRef.current.querySelectorAll(".e-modal")) || []  // only for top most modal
      if (childModals.length > 0) return
      onClickHide()
    } 
  }
  
  useEffect(() => { setHeaderLabelId(newId())}, [])

  useImperativeHandle(ref, () => ({
    show(){ 
      setTimeout(() => {
        setShowing(true) 
        // @ts-ignore
        setActiveElement(document.activeElement)
        if (!skipFocus) setFirstFocus()
        document.addEventListener('keydown', handleKeypress)
        adjustPosition()
      }, 100)// the delay is for restoring focus when it pops from a dropdown menu.
      
    },
    hide(){ 
      handleHide() 
      if (firstFocusRef.current?.removeEventListener) {
        firstFocusRef.current.removeEventListener('keydown', onKeyDownFirst)
      }
    },
    setFirstFocus(){
      if (!skipFocus) setFirstFocus()
    },
    adjustPosition(){
      adjustPosition()
    }
  }))
  
  const setFirstFocus = () => setTimeout(()=>{
    firstFocusRef.current = focusOnSelectors2(modalRef, focusElements)
    if (firstFocusRef.current) {
      firstFocusRef.current.addEventListener('keydown', onKeyDownFirst)
    }
    if (defaultFocus) focusOnSelectors2(modalRef, defaultFocus)
  }, 100)

  const onKeyDownFirst = (event: any)=>{
    if (event.which === 9 && event.shiftKey) {
      event.preventDefault()
      lastFocusRef.current.dispatchEvent(new KeyboardEvent('keydown',{'key':'Shift'}))
    }
  }

  const onKeyDownLast = () =>{}

  const onFocusLast = (event: any)=>{
    event.preventDefault()    
    setFirstFocus()
  }

  const onClickHide = () => {
    if(breakHide()){
      return
    }
    handleHide()
  }

  const handleHide = () => {
    setShowing(false)
    if(!!refocus){
      let refocusEle: any = document.querySelector(refocus)
      refocusEle && refocusEle.focus()
    } else{    
      restoreFocus && activeElement.focus && activeElement.focus()
    }
    document.removeEventListener('keydown', handleKeypress)
    onHide()
  }

  const adjustPosition = () => {
    setTimeout(() => {
      if(!modalRef.current){
        return
      }
      
      const rect = modalRef.current.getBoundingClientRect()
      const parentModal: any = findParent(modalRef.current, '.e-modal-dialog')
      const pWidth = parentModal ? parentModal.getBoundingClientRect().width : window.innerWidth
      setDndStyle({left: (pWidth - rect.width)/2 + 'px'})
    });
    
  }

  loggerInfo('modal.render')
  return <span className={`${showing ? 'e-modal' : 'e-modal-hide'} ${modalName}`}>
    <div className='e-modal-background'></div>
    <Draggable handle='.e-modal-header' bounds='parent'>
      <div tabIndex={0}  ref={modalRef} role={!!role? role: 'dialog'} aria-modal={true} aria-labelledby={labelledBy ? labelledBy: headerLabelId} 
        aria-describedby={describedBy} className={`e-modal-dialog ${className}`} style={{...style, ...dndStyle}} {...rest}>
        <div className={`e-modal-header ${!!header && styleHeaderUnderline ? 'header' : ''}`}>
        {headerWrapped ? <span className='header-text'>
          {header ? <span tabIndex={0} role='heading' aria-level={3} id={headerLabelId}>{header}</span> : <span tabIndex={-1} id={headerLabelId}>{'\u00A0'}</span>
          }
          </span>
            : header}

          {!showClose ? <span/> :
          <Button tabIndex='0' className='e-modal-close' onClick={onClickHide}>
            {/* <span className='fa fa-close'></span> */}
            <img alt='Close Modal' src='./img/icon-close.svg'/>
          </Button>
          }

        </div>
        {children}
        {footer && <div className='e-modal-footer'>{footer}</div>}
        <div tabIndex={0} className='last-focus' ref={lastFocusRef} onKeyDown={onKeyDownLast} onFocus={onFocusLast}></div>
      </div>
    </Draggable>
  </span>
})