import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { DropDownMenu, AnimateInput, OdsInput } from '.'
import { loggerInfo, newId } from 'utils'
import './typing-input.scss'
      
export default forwardRef((props: any, ref) =>  {
  const { value, label, labelledBy, describedBy, hasGroup=false, closeOnSelect=true, maxLength, placeholder, autoFocus, children, onChange=()=>{}, 
    menuStyle, styles, extra, extra2, menuHeader, pullUp, pullRight, typingDrop=true, autoDrop=true, fixedDrop=false,
    onClick=()=>{}, onDropMenu=()=>{}, onBlur=()=>{}, ods, odsLabel, ...rest } = props
 
  const [menuId, setMenuId] = useState({})
  const [menuExpanded, setMenuExpanded] = useState(false)
  const dropRef: any = useRef({})
  const inputRef: any = useRef({})

  const api = () => ({
    setValue: (v: any) => inputRef.current.value=v,
    getValue: () => inputRef.current.value,
    hideDropMenu: (restoreFocus: any) =>dropRef.current.hideDropMenu(restoreFocus),
    showDropMenu: () =>dropRef.current.showDropMenu(),
    isToggleOn: () =>dropRef.current.isToggleOn(),
    contains: (e: any) => dropRef.current.contains(e),
    focus: () => setTimeout(()=>inputRef.current && inputRef.current.focus(), 500)
  })
  useImperativeHandle(ref, api)

  useEffect(() => setMenuId(newId()), [])
  useEffect(() => {
    if (value) inputRef.current.value = value
  }, [value])

  useEffect(() => {
    if (autoFocus) {
      setTimeout(()=>inputRef.current && inputRef.current.focus())
    }
  }, [autoFocus])

  const onInput = (event: any) =>{
    onChange(event)
    if(!typingDrop){
      return
    }
    
    if(!!!event.target.value){
      dropRef.current.hideDropMenu()
      return
    }
    dropRef.current.showDropMenu()
  }
  const onInputClick = (event: any) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    // if(extra) return
    onClick(event)
  }

  const _onDropMenu = (isShow: any, event: any) => {
    setMenuExpanded(isShow)
    onDropMenu(isShow, event)
  }

  loggerInfo('typing-input2.render')
  return (
    <div className={`typing-input ${pullUp ? 'pullUp' : ''}`}>
      <div className='simple-row'>
        <DropDownMenu scrollable id={menuId} fixed={fixedDrop} ref={dropRef} menuRole="listbox" hasGroup={hasGroup} closeOnSelect={closeOnSelect} autoFocus={false} menuLabelledBy={labelledBy} focusable={true} 
          menuStyle={menuStyle} 
          onBlur={onBlur} onDropMenu={_onDropMenu}
          menuHeader={menuHeader} pullUp={pullUp} autoDrop={autoDrop} spaceDown={false} pullRight={pullRight}
          display={ods ? 
          <OdsInput ref={inputRef} {...(maxLength && {maxLength: maxLength})} aria-controls={menuId} aria-haspopup="listbox" value={value} aria-expanded={menuExpanded} labelledBy={labelledBy} describedBy={describedBy} onClick={onInputClick} onChange={onInput} style={styles} 
            extra={<><span>{extra2}</span><span>{extra}</span></>} autoComplete='off' {...rest}
              label={odsLabel}
              // desc={<>Your goal title will appear in the goal menu for easy access</>}
            />
            :
            <AnimateInput ref={inputRef} {...(maxLength && {maxLength: maxLength})} aria-controls={menuId} aria-haspopup="listbox" value={value} aria-expanded={menuExpanded} labelledBy={labelledBy} describedBy={describedBy} label={label} onClick={onInputClick} onChange={onInput} placeholder ={placeholder} style={styles} 
            extra={<><span>{extra2}</span><span>{extra}</span></>} autoComplete='off' {...rest}/>
          }
        >{children}</DropDownMenu>
      </div>
    </div>
  )
})