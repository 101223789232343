import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Key, addToRefList, newId, loggerInfo } from 'utils'
import { DropDownMenu, Div } from 'components/common'
import './dropdown.scss'

export default forwardRef((props: any, ref) => {
    const { items, menuRole = 'menu', menuLabelledBy, /*closeOnSelect=false,*/ display, subMenuLabelStyle, subMenuClass, subMenuStyle, onDropMenu=()=>{}, pullRight=true } = props

    const [menuId, setMenuId] = useState('')
    const [toggle, setToggle] = useState(false)

    const wrapperRef: any = useRef({})
    const subMenusRef: any = useRef([])

    useEffect(() => setMenuId(newId()), [])
    useImperativeHandle(ref, () => ({
        showDropMenu(e: any) { wrapperRef.current.showDropMenu(e) },
        hideDropMenu(e: any) { wrapperRef.current.hideDropMenu(e) }
    }))

    const addToRef = (el: any) => {
        addToRefList(subMenusRef, el)
    }

    const findItemColIdx = (idx: number) => items.filter((item: any, i: number)=>i<=idx && item.children).length - 1

    const onKeyDown = (e: any, i: number) => {
        if (e.keyCode === Key.LEFT_ARROW && i >= 0) {
            e.preventDefault()
            e.stopPropagation()
            let subMenu = subMenusRef.current[findItemColIdx(i)]
            if (subMenu) {
                subMenu.hideDropMenu(e)
                // subMenu.clear()
            }
            setToggle(false)
        } else if (e.keyCode === Key.RIGHT_ARROW && i >= 0) {
            e.preventDefault()
            e.stopPropagation()
            let subMenu = subMenusRef.current[findItemColIdx(i)]
            if (subMenu) {
                subMenu.showDropMenu(e)
                setTimeout(()=>subMenu.focusOnFirst())
                e.target.setAttribute('tabindex', '-1')
            }
            setToggle(true)
        } else if (e.keyCode === Key.UP_ARROW) {
            e.preventDefault()
            e.stopPropagation()
            let prev = e.target.previousElementSibling
            if (prev) {
                e.target.setAttribute('tabindex', '-1')
                prev.setAttribute('tabindex', '0')
                prev.focus()
            }
        } else if (e.keyCode === Key.DOWN_ARROW) {
            e.preventDefault()
            e.stopPropagation()
            let next = e.target.className.indexOf('dropdown-item') >-1 && e.target.nextElementSibling
            if (next) {
                e.target.setAttribute('tabindex', '-1')
                next.setAttribute('tabindex', '0')
                next.focus()
            }
        }
    }

    const _onDropMenu = (e: any) => {
        !e && wrapperRef.current.clear()
        setToggle(false)
        onDropMenu(e)
      }

    const getItemRole = () => menuRole === 'listbox' ? 'option' : 'button'

    const constructMenuItem = (item: any, i: number) => {
        if (item.children) {
            return (
                <Div key={i} role={getItemRole()} className='dropdown-item'
                    aria-haspopup={menuRole} aria-expanded={toggle} onKeyDown={(e: any) => onKeyDown(e, i)}>
                    <DropDownMenu ref={addToRef} id={`${menuId}_${i}`} menuRole={menuRole} menuLabelledBy={`${menuId}_${i}_lbl`} focusable={true} closeOnSelect={!item.isMultiSelect}
                        display={<span className="dropdown-item-label" style={subMenuLabelStyle} id={`${menuId}_${i}_lbl`}>{item.label} <span aria-hidden={true} className='fa fa-caret-right' style={{paddingTop: '5px', paddingLeft: '10px'}}/></span>}
                        menuClassName={subMenuClass} menuStyle={subMenuStyle}>
                        {item.children.map((c: any, j: number) => constructMenuItem(c, i + j))}
                    </DropDownMenu>
                </Div>
            )
        } else {
            if (item.action) {
                return <Div key={i} role={getItemRole()} title={item.title} className='dropdown-item auto-wrap' onKeyDown={(e: any) => onKeyDown(e, -1)} onClick={item.action}>
                    {item.checked && <span aria-hidden='true' className='fa fa-check'/>}{item.label}
                    </Div>
            } else {
                return <div key={i} role={getItemRole()}  title={item.title} {...(menuRole==='listbox' && {ariaSelected: true})} className={`dropdown-item auto-wrap disabled ${item.className}`}>
                    {item.checked && <span aria-hidden='true' className='fa fa-check'/>}{item.label}
                    </div>
            }
        }
    }

    loggerInfo('dropdown-multi-level render')

    return (
        <DropDownMenu ref={wrapperRef} pullRight={pullRight} closeOnSelect={false} menuRole={menuRole} menuLabelledBy={menuLabelledBy} display={display} onKeyDown={onKeyDown} onDropMenu={_onDropMenu}>
            {
                items && items.map(constructMenuItem)
            }
        </DropDownMenu>
    )
})
