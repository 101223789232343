import React from 'react'
import { displayUserName } from 'utils'
import { StepName as StepNameW } from '../types'
import { StepName as StepNameE } from '../../editing/types'

export const BannerMsg = {
  init: 'init',
  typeInfo: 'typeInfo',  
  typeClose: 'typeClose',
  typeSkip: 'typeSkip',

  descSkip: 'descSkip',
  descCloseW: 'descCloseW',
  descCloseE: 'descCloseE',
  
  prioritySkip: 'prioritySkip',
  priorityCloseW: 'priorityCloseW',
  priorityCloseE: 'priorityCloseE',

  measureSkip: 'measureSkip',
  measureCloseW: 'measureCloseW',
  measureCloseE: 'measureCloseE',

  updateSkip: 'updateSkip',
  updateCloseW: 'updateCloseW',
  updateCloseE: 'updateCloseE',

  skillSkip: 'skillSkip',
  skillCloseW: 'skillCloseW',
  skillCloseE: 'skillCloseE',

  activitySkip: 'activitySkip',
  activityCloseW: 'activityCloseW',

  roleSkip: 'roleSkip',
  roleCloseW: 'roleCloseW',
  
  shareErrorP: 'shareErrorP',
  shareErrorL: 'shareErrorL',
  shareError: 'shareError',
  shareDone: 'shareDone',

  createDone: 'createDone',
}
export const contents: any = {  
  [BannerMsg.typeInfo]: ()=>({
    step: [StepNameW.WTYPE],
    type: 'info',
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      Use these pop-ups to fill out your goal (you can skip steps that you want to fill in later, and edit anything you've entered on your goal details page), or select 'close' to add to your goal from the goal details page instead.
    </div>
  }),
  [BannerMsg.typeClose]: ()=>({
    step: [StepNameW.WTYPE],
    noclose: true,
    type: 'warning',
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your title, select 'save and continue'. If you don't want to save your title, select 'close' and your goal won't be set up. 
    </div>
  }),  
  [BannerMsg.descSkip]: ()=>({
    step: [StepNameW.WDESC],
    noclose: true,
    type: 'warning',
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your description, select 'save and continue'. If you don't want to save your description, select 'skip'. 
    </div>
  }),
  [BannerMsg.descCloseW]: ()=>({
    step: [StepNameW.WDESC],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your description, select 'save and continue'. If you don't want to save your description, select 'close'.  
    </div>
  }),
  [BannerMsg.descCloseE]: ()=>({
    step: [StepNameE.EDESC],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your description, select 'save'. If you don't want to save your description, select 'close'.
    </div>
  }),
   
  [BannerMsg.prioritySkip]: ()=>({
    step: [StepNameW.WPRIO],
    noclose: true,
    type: 'warning',
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your selections, select 'save and continue'. If you don't want to save your selections, select 'skip'. 
    </div>
  }),
  [BannerMsg.priorityCloseW]: ()=>({
    step: [StepNameW.WPRIO],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your selections, select 'save and continue'. If you don't want to save your selections, select 'close'. 
    </div>
  }),
  [BannerMsg.priorityCloseE]: ()=>({
    step: [StepNameE.EPRIO],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your selections, select 'save'. If you don't want to save your selections, select 'close'.
    </div>
  }),
  [BannerMsg.measureSkip]: ()=>({
    step: [StepNameW.WMEAS],
    noclose: true,
    type: 'warning',
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your performance measure(s), select 'save and continue'. If you don't want to save your performance measure(s), select 'skip'.  
    </div>
  }),
  [BannerMsg.measureCloseW]: ()=>({
    step: [StepNameW.WMEAS],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your performance measure(s), select 'save and continue'. If you don't want to save your performance measure(s), select 'close'.  
    </div>
  }),
  [BannerMsg.measureCloseE]: ()=>({
    step: [StepNameE.EMEAS],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your performance measure(s), select 'save'. If you don't want to save your performance measure(s), select 'close'.
    </div>
  }),
  [BannerMsg.updateSkip]: ()=>({
    step: [StepNameW.WUPDA],
    noclose: true,
    type: 'warning',
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your progress update, select 'save and continue'. If you don't want to save your progress update, select 'skip'. 
    </div>
  }),
  [BannerMsg.updateCloseW]: ()=>({
    step: [StepNameW.WUPDA],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your progress update, select 'save and continue'. If you don't want to save your progress update, select 'close'.  
    </div>
  }),
  [BannerMsg.updateCloseE]: ()=>({
    step: [StepNameE.EUPDA],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your progress update, select 'save'. If you don't want to save your progress update, select 'close'.  
    </div>
  }),
  [BannerMsg.skillSkip]: ()=>({
    step: [StepNameW.WSKIL],
    noclose: true,
    type: 'warning',
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your skills, select 'save and continue'. If you don't want to save your skills, select 'skip'.
    </div>
  }),
  [BannerMsg.skillCloseW]: ()=>({
    step: [StepNameW.WSKIL],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your skills, select 'save and continue'. If you don't want to save your skills, select 'close'.
    </div>
  }),
  [BannerMsg.skillCloseE]: ()=>({
    step: [StepNameE.ESKIL],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your skills, select 'save'. If you don't want to save your skills, select 'close'.
    </div>
  }),
  [BannerMsg.activitySkip]: ()=>({
    step: [StepNameW.WACTI],
    noclose: true,
    type: 'warning',
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your learning activities, select 'save and continue'. If you don't want to save your learning activities, select 'skip'.
    </div>
  }),
  [BannerMsg.activityCloseW]: ()=>({
    step: [StepNameW.WACTI],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your learning activities, select 'save and continue'. If you don't want to save your learning activities, select 'close'.
    </div>
  }),
  [BannerMsg.roleSkip]: ()=>({
    step: [StepNameW.WROLE],
    noclose: true,
    type: 'warning',
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your selection, select 'save and continue'. If you don't want to save your selection, select 'skip'.
    </div>
  }),
  [BannerMsg.roleCloseW]: ()=>({
    step: [StepNameW.WROLE],
    type: 'warning',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      To save your selection, select 'save and continue'.  If you don't want to save your selection, select 'close'.
    </div>
  }),
  [BannerMsg.shareErrorP]: ()=>({
    step: [StepNameW.WSHAR, StepNameE.ESHAR],
    type: 'error',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      Before you share with your manager, add a description and one performance measure. 
    </div>
  }),
  [BannerMsg.shareErrorL]: ()=>({
    step: [StepNameW.WSHAR, StepNameE.ESHAR],
    type: 'error',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      Before you share with your manager, add a description.
    </div>
  }),
  [BannerMsg.shareError]: ()=>({
    step: [StepNameW.WSHAR],
    type: 'error',
    noclose: true,
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      This goal has been shared. 
    </div>
  }),
  [BannerMsg.shareDone]: (plan: any, goal: any)=>({
    step: [StepNameW.WUPDA],
    type: 'check',
    description: <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
      Your goal ({goal.name}) has been shared with your manager, {displayUserName(plan.manager)}
    </div>
  }),
  [BannerMsg.createDone]: (plan: any, goal: any)=>({
    step: [StepNameW.WDONE],
    type: 'check',
    noclose: true,
    description: goal.type?.code === 'PROJECT' ?
      <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
        Congratulations, your goal has been set up! You can edit your goal on the goal details page.
      </div>
      :
      goal.status?.code === 'DRAFT' ?
      <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
        Congratulations, your goal has been set up! You can edit your goal on the goal details page.
      </div>
      :
      <div className='simple-row' style={{padding: '0px 15px 0 15px'}}>
        Congratulations, your goal has been set up and shared with your manager! You can edit your goal on the goal details page.
      </div>
  }),
}