import React, {useState, useEffect} from 'react'
import { ImgButton, OdsImage } from 'components/common'
import { newId } from 'utils'
import './gadget.scss'

const Gadget = (props: any) => {
  const { opened, parentId, headerText, extra, children, describedBy, onOpen=()=>{}, ...rest } = props
  const [containerId, setContainerId] = useState('')
  const [collapse, setCollapse] = useState(!!!opened)

  useEffect(()=>setContainerId(newId()), [])
  useEffect(() => { setCollapse(opened) }, [opened, parentId])
  const onCollapse = () => {
    const _collapse = collapse
    setCollapse(!_collapse)
    onOpen(!_collapse)
  }
  
  return <div className='gadget' {...rest}>
    {/* <ImgButton //image={`fa fa-chevron-${collapse? 'down' : 'right'}`} 
      src={`./img/icon-chevron-${collapse ? 'down':'right'}.svg`}
      onClick={onCollapse} role="button" 
      aria-expanded={collapse} aria-controls={containerId}
      className='indicator'>
      {headerText}
    </ImgButton> */}

    <OdsImage onClick={onCollapse} href={`ontario-icon-chevron-${!collapse ? 'right': 'down'}`}
      aria-expanded={collapse} aria-controls={containerId}
      className='indicator'>
      {headerText}
    </OdsImage>
    <span className='extra'>{extra}</span>    
    <div id={containerId} tabIndex={0} className='simple-row gadget-container'> {collapse && children}</div>
  </div>
}

export default Gadget