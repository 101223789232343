import { useEffect, useRef } from 'react'
import './style.scss'

interface propsType{
	id?: any;
	children?: any;
	display?: string;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const OdsDropdown = (props: propsType) => {
	const { id, display='Select', children, onClick } = props
	const refDropdown: any = useRef({})

	useEffect(() => {
		const cb_show = () => {
			// console.log('show')
		}
		const cb_hide = () => {
			refDropdown.current.querySelector('.dropdown-toggler').focus()
		}
		refDropdown.current.addEventListener('show.bs.dropdown', cb_show)
		refDropdown.current.addEventListener('hide.bs.dropdown', cb_hide)
		return ()=>{
			refDropdown.current?.removeEventListener('show.bs.dropdown', cb_show)
			refDropdown.current?.removeEventListener('hide.bs.dropdown', cb_hide)
		}
	}, [])
	

	return (<div ref={refDropdown} className='ods-dropdown btn-group'>
	<button id={id} className='ontario-button dropdown-toggler' data-bs-toggle='dropdown' data-bs-display='static' aria-expanded='false'
			onClick={onClick}>
		<span>{display}</span><span aria-hidden='true' className='fa fa-caret-down'></span>
	</button>
	<ul className={`dropdown-menu${!children || children.length === 0 ? ' d-none' : ''}`}>
		{children}
	</ul>
</div>)
}
export default OdsDropdown