export interface Config{
    actionAs: number;
    authority: string;
    cacheLocation: string;
    clientId: string;
    graphCodeReady: boolean;
    loginRedirectUri: string;
    multipleTenants: [any];
    postLogoutRedirectUri: string;
    readyTester: [any];
    redirectUri: string;
    tenant: string;
    tokenRenewalTimeout: number;
}
export interface FetchActionType{
    type?: null | any;
    failure?: (error: any) => any; 
    ignore?: null | any; 
    spinner?: boolean; 
    path: string; 
    method: string; 
    body?: any; 
    signal?: AbortSignal;
    success: (data: any) => void
}
export interface SimpleActionType{
    type: string;
    value?: any;
}
export enum SortDirection{
    ASC = 'ASC', 
    DESC = 'DESC'
}

export interface UserBaseDto{
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
    displayName: string;
    email: string;
    phone: string;
    winId: string;
    minInAD: string;

    positionId: string;
    position: string;
    jobCategory: string;
    jobLevel: string;
    ministry: string;

    mentorSpot: number;
    topSkills: ValueListDto[];
}

export class UserBase {
    id!: number;
    firstName!: string;
    middleName!: string;
    lastName!: string;
    displayName!: string;
    email!: string;
    winId!: string;
    hasProfile!: number;
    reportToPlanNumberByWin!: number;
    minInAD!: string;
			
	//for talent card
    opsAdId!: string;
    positionId!: string; //fill posNum
    position!: string;
    jobCode!: string;
    jobClass!: string;
    jobCategory!: string;
    jobLevel!: string;

    ministry!: string;
    phone!: string;
	
	//for position detail
    profUUID!: string; //TODO: remove?
    fromDate!: Date;
    winManager!: number;
    tmMinId!: number;
		
	//For goal lib list employee
    planId!: string;
    planYear!: string;
    requirePIPStatus!: number;
    exemptionPIP!: number;
}
export class Person extends UserBase {	
    adId!: string; //UI identify user
    adId2!: string;
    fax!: string;
    division!: string;
    branch!: string;
    thumbnail!: string;
    reportTo!: Person;
    active: boolean = true;
}
export class ValueListBase{
    id!: number;
    type!: string;
    code!: string;
    label!: string;
}
export class ValueListDto{
    id!: number;
    ver!: number;
    parentId!: number;
    type!: string;
    code!: string;
    label!: string;
    value!: string;
    jsonData!: string;
    description!: string;
    displayOrder!: number;
    businessData!: number;
    active: number = 1;
    parent!: ValueListDto;
}
export interface CustomDetailJson{
    custom: string;     //self input
}
enum ValueListRole{ //ROLE
    // SYS_ADMIN(TmsConstants.ROLE_SYS_ADMIN)
    // , CORP_ADMIN(TmsConstants.ROLE_CORP_ADMIN)
    // , MIN_ADMIN(TmsConstants.ROLE_MIN_ADMIN)
    // , WIN_MGR(TmsConstants.ROLE_WIN_MGR)
    
    // , RECRUITER(TmsConstants.ROLE_RECRUITER)
    // , EXE_RECRUITER(TmsConstants.ROLE_EXE_RECRUITER)
    // , EXE_ADVISORY(TmsConstants.ROLE_EXE_ADVISORY)
    // , COMM_LEAD(TmsConstants.ROLE_COMM_LEAD)
    
    // , OSC(TmsConstants.ROLE_OSC)
    // , HRA(TmsConstants.ROLE_HR_ADVISOR)
    // , CONTENT_ADMIN(TmsConstants.ROLE_CONTENT_ADMIN)
    // , STRAT_ADMIN(TmsConstants.ROLE_STRAT_ADMIN)
    
    // , PPRA_ADMIN(TmsConstants.ROLE_PPRA_ADMIN)
}
interface MinistryScope {
    minId: number;
    roleName: string;
}
enum ValueListRolePosition{ //ROLE_POS
    PERF_RPT, 
    PERF_PLAN, 
    SUCS_ADM
}
export class SecurityUser{
    userId!: string; //AdId
    displayName!: string;
    firstName!: string;
    middleName!: string;
    lastName!: string;
    email!: string;   //By AD token, will match by email or upn in individual table
    iss!: string;
    active: boolean = true;
    userPK!: string; // Id in Document or Email for Feedback Client
    profileId!: number;
    mentorProfId!: number;  // -1, block the tab access, it means the mentorship service down
    isMentee!:number;
    mentorProfStatus!: number;  //1: ready, 0: draft
    planId!: number; //latest Plan id
    posNum!: string; //latest active Assignment id
    appScope: ValueListRole[] = [];  //name of the other roles
    minScope: MinistryScope[] = [];
    ppraScope: ValueListRolePosition[] = [];
    manager!: number;
    winManager!: number;      //Mark as manager in Win system, this sign for talent search
    extendManager!: number;   // build by Win position report
    extTeamWizard!: number;   //can back to 0 if the user lost ext team access
    rsListWizard!: number;   //can back to 0 if the user lost ext team access
    clientAccess!: number;     // client access feedback; access shared profile use the value
    hasPlan: number = 1; 	 //0: Profile Only and Feedback Client; 1: normal user
    hasProfile: number = 1;  //0: for feedback client; 1: normal user
    acknowledge: number = 0;
    praplanNewUIAck: number = 0;
    renewCode!: string;
    firstTimeAccess: number = 0;
}
export class DataLockDto{
    id!: number;
    ver!: number;
    onBehalf!: number;
    acquireSuccess!: number; //1: get lock success; 0: get lock failed, return current lock holder info
    entityId!: number;
    entityGroupId!: number;
    entityTypeVLId!: number;   //VL mapping
    entityFieldVLId!: number;  //VL mapping
    excludeLock!: number;
    lock!: number;
    lockAt!: number;  //0: no stage; 1: after employee submit rating request
    lockByIndId!: number;
    lockBy!: UserBase;
    comment!: string;
}
export class DocumentDto{
    id!: number;
    ver!: number;    //init is doc create time, when used for update history data, it should be the plan last update time as ver
	active: number = 1;
    fileName!: string;
    docName!: string;
    fileType!: ValueListDto;
    description!: string;
}
export class AdUserDto{
    mail!: string;
    email!: string;
    displayName!: string;
    fullName!: string;
    firstName!: string;
    lastName!: string;
    adId!: string;
    adId2!: string;
    phone!: string;
    fax!: string;
    position!: string;
    ministry!: string;
    division!: string;
    branch!: string;
    address!: string;
    province!: string;
    city!: string;
    country!: string;
    postcode!: string;
    objectGuid!: string;
    msdsConsistencyGuid!: string;
    adCreateAt!: Date;
    adUpdateAt!: Date;
}
export class UINotificationEventDto{
    id!: number;
    etype!: string;
    planId!: number;
    employee!: UserBase;  //IMPROVE_GOAL_CHGJOB_NEWMGR
		
	//IMPROVE_GOAL_DELETE
    goalName!: string;
    planYear!: string;
    assPosition!: string;
	
	//IMPROVE_GOAL_CLOSE_EMPL
    manager!: UserBase;   //IMPROVE_GOAL_CHGJOB_NEWMGR
    comment!: string;
    goalStatus!: ValueListDto;
	
	//PROFILE_LANG_CERT_EMPL
    profileId!: number;
    langCertCate!: ValueListDto;
    langCertVerbal!: ValueListDto;
    langCertWritten!: ValueListDto;
    
    eventBy!: UserBase;
    eventAt!: number;
}
export enum NotifyUIEventType{
    IMPROVE_GOAL_REQUIRED_MGR = 'IMPROVE_GOAL_REQUIRED_MGR',  //("TM_PRA_PLAN"),   //Trigger by Red Rating and send to current plan Manager
    IMPROVE_GOAL_DELETE_MGR = 'IMPROVE_GOAL_DELETE_MGR',    //("TM_PRA_PLAN"),     //Trigger by Admin delete the PIP goal and send to Mgr
    IMPROVE_GOAL_DELETE_EMPL = 'IMPROVE_GOAL_DELETE_EMPL',   //("TM_PRA_PLAN"),    //Trigger by Admin delete the PIP goal and send to Empl
    IMPROVE_GOAL_CHGJOB_NEWMGR = 'IMPROVE_GOAL_CHGJOB_NEWMGR', //("TM_PRA_PLAN"),  //Trigger by employee change the job and send to new manager, by win-import / admin manual created plan
    IMPROVE_GOAL_DEPLOY_EMPL = 'IMPROVE_GOAL_DEPLOY_EMPL',   //("TM_PRA_GOAL"),    //Trigger by manager deploy IG and send to Empl
    NORMAL_GOAL_DEPLOY_EMPL = 'NORMAL_GOAL_DEPLOY_EMPL',   //("TM_PRA_GOAL"),    //Trigger by manager deploy normal goal and send to Empl
    IMPROVE_GOAL_CLOSE_EMPL = 'IMPROVE_GOAL_CLOSE_EMPL',    //("TM_PRA_GOAL"),      //Trigger by manager complete/incomplete the PIP goal and send to Empl
    PROFILE_LANG_CERT_EMPL = 'PROFILE_LANG_CERT_EMPL',     //("TM_PROFILE"),       //Trigger by new Assignment FLS fields, not trigger by update value
    PROFILE_LANG_CERT_DEL_EMPL = 'PROFILE_LANG_CERT_DEL_EMPL', //("TM_PROFILE"),   //Trigger by no Assignment FLS fields, not trigger by update value=
  }