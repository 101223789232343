import React , { Suspense, lazy, useState, useEffect, useRef, StrictMode }from 'react'
import { useIsAuthenticated } from "@azure/msal-react"
import { storeRedirect, restoreRedirect, setActionAsProd, isBpsLanding, isBpsLandingWait, setAdalConfig, getMsalLoginStatus, setPublicValuelist, isLoginPrompt } from 'utils/routing-util'
import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'scss/ontario-design-system.scss'
import 'scss/app.scss'
import LoginTester from 'components/login'
import MessagePage from './message-page'
import { fetch_redirect, fetch_public_vl, msalLogin } from './action'
import { Config } from 'types'
import OdsIcons from 'components/common/ods/icons'

const App = lazy(() => import('./app'))
const Exceptions = lazy(() => import('./exceptions'))
const Landing = lazy(() => import('./components/auth/landing'))
const initLoading = {status: 1}
interface propsType{
  config?: Config;
  error?: any;
}
const Initialization = (props: propsType) => {
  const { config, error } = props
  const [loading, setLoading] = useState(initLoading)
  const [_message, setMessage] = useState({content: 'Please wait while the application loads ...'})

  const isAuthenticated = useIsAuthenticated()
  const redirectRef = useRef({})
  const ready2go = (() => isAuthenticated && getMsalLoginStatus() !== '1' && !isLoginPrompt())()

  useEffect(() => { 
    storeRedirect()
    !error && startApp() 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, error])

  useEffect(() => { ready2go && launch() }, [isAuthenticated])
  
  const startApp = () => {
    setAdalConfig(config)
    if(isBpsLandingWait()){
      setLoading(initLoading)
    }else if(isBpsLanding()){
      fetch_public_vl((values: any)=>{
        setPublicValuelist(values)
        setLoading({...loading, status: -1 })
      }, setMessage)
    }else{
      run()
    }
  }

  const run = () => {
    if(!config?.actionAs){
      setActionAsProd('')
      LoginTester(config?.readyTester, launch)
      return
    }
    
    setActionAsProd('prod')
    storeRedirect()
    !ready2go && msalLogin()
  }  

  const redirectMe = (redirectTo: string) => {
    redirectRef.current = redirectTo
    const path = `${window.location.protocol}//${window.location.host}/api${redirectTo.substring(1)}`

    fetch_redirect(path, (data: any) => {
        window.location.hash = data.redirect
        setLoading({...loading, status: 0})
      }, setMessage)
  }
  
  const launch = () =>{    
    const redirectTo = restoreRedirect()
    if (redirectTo) {
      redirectMe(redirectTo)
    } else {
      setLoading({...loading, status: 0})      
    }
  }

  const message = error || _message

  return (<Suspense fallback={<MessagePage/>}>{
    loading.status === -1 || message.statusCode === 255 ? 
    <Landing/>
    : 
    // loading.status === 0 ? <StrictMode><App/></StrictMode>    
    loading.status === 0 ? <><OdsIcons/><App/></>
    :
    <Exceptions message={message} redirectLink={redirectRef.current}>
      <MessagePage message={message}/>
    </Exceptions>
  }</Suspense>)
}

export default Initialization
