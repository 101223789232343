import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { Key, newId } from '../../utils'
// eslint-disable-next-line
import { prompt } from './actions'
import './toggle.scss'

export default forwardRef((props:any, ref) => {
  const { label, labelledBy, onChange=()=>{}, showMessage=()=>{}, resetMessage=()=>{}, readOnly=false, message, checked, ...rest } = props
  const [ id, setId ] = useState('')
  const __ref = useRef({})
  const _ref = ref || __ref

  useEffect(() => { 
    setId(newId()) 
  }, [])

  const disabled = readOnly ? {'disabled': 'disabled'} : {};

  const onCheckChange = (event:any) => {
    if (!readOnly) {
      onChange(event)
      resetMessage()
    }
  }
  
  const onKeyDown = (e:any) =>{
    if(e.keyCode===Key.SPACE) {
      e.preventDefault()

      if (!readOnly) {
        e.target.value = !checked ? 'on': ''
        onCheckChange(e)
      } else {
        showMessage()
      }
    }
  }

  const showDisableMessage = (event:any) => {
    readOnly && showMessage()
    event.stopPropagation()
  }

  return  (
      <label htmlFor={id} className="switch">
        <div onClick={showDisableMessage}>
          <input ref={_ref} id={id} type="checkbox" aria-labelledby={labelledBy} onKeyDown={onKeyDown} checked={checked||false} {...rest} 
            {...disabled} onChange={onCheckChange}/>
          <span className="slider round"> </span>
          <span className="toggle-label">{label}</span>
        </div>
      </label>
    )
})